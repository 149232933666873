@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);
html, body {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App_js_App__29e2B {
  text-align: center;
}

.App_js_App-logo__3brFl {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App_js_App-logo__3brFl {
    animation: App_js_App-logo-spin__2skfs infinite 20s linear;
  }
}

.App_js_App-header__1litx {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App_js_App-link__170Pq {
  color: #61dafb;
}

.App_js_loginContainer__3cyGa {
  padding-top: 1rem;
  position: relative;
}
.App_js_loginGrid__2-dzv {
  width: 80vw;
}
.App_js_mrgnSml__JX3G5 {
  margin-bottom: 0.8rem;
}
.App_js_mrgn__1s_yN {
  margin-bottom: 2.5rem;
}
.App_js_description__bF1Oe {
  width: 50%;
}

@keyframes App_js_App-logo-spin__2skfs {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Header_container__7YgNF {
  display: flex;
  width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto 1rem;
  font-size: 0.9rem;
  padding-bottom: 1rem;
}

.Header_flex__3Sgod {
  display: flex;
  align-items: center;
}

.Header_nsw_logo__3YADi, .Header_logo__1_msB {
  object-fit: contain;
  width: 7rem;
}

@media only screen and (max-width: 500px) {
  .Header_logo__1_msB {
    display: none;
  }
}
