.container {
  display: flex;
  width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto 1rem;
  font-size: 0.9rem;
  padding-bottom: 1rem;
}

.flex {
  display: flex;
  align-items: center;
}

.nsw_logo, .logo {
  object-fit: contain;
  width: 7rem;
}

@media only screen and (max-width: 500px) {
  .logo {
    display: none;
  }
}